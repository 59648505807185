const product1 = {
  title: 'Contemplation',
  size: '12" by 12" ',
  imageArray: ['/images/slideshow-1.webp', '/images/slideshow-1.webp'],
  description: 'Mixed Media',
  status: 'Sold',
  price: '$475',
};

const product2 = {
  title: 'Dejection',
  size: '12" by 12" ',
  imageArray: ['/images/slideshow-2.webp', '/images/slideshow-2.webp'],
  description: 'Mixed Media',
  status: 'Available',
  price: '$475',
};

const product3 = {
  title: 'Hopeful',
  size: '12" by 12" ',
  imageArray: ['/images/slideshow-3.webp', '/images/slideshow-3.webp'],
  description: 'Mixed Media',
  status: 'Sold',
  price: '$475',
};

const product4 = {
  title: 'Hopeless',
  size: '12" by 12" ',
  imageArray: ['/images/slideshow-4.webp', '/images/slideshow-4.webp'],
  description: 'Mixed Media',
  status: 'Available',
  price: '$475',
};

const product5 = {
  title: 'Lost',
  size: '12" by 12" ',
  imageArray: ['/images/slideshow-5.webp', '/images/slideshow-5.webp'],
  description: 'Mixed Media',
  status: 'Sold',
  price: '$475',
};

const product6 = {
  title: 'Plea',
  size: '12" by 12" ',
  imageArray: ['/images/slideshow-6.webp', '/images/slideshow-6.webp'],
  description: 'Mixed Media',
  status: 'Available',
  price: '$475',
};

const product7 = {
  title: 'Trapped',
  size: '12" by 12" ',
  imageArray: ['/images/slideshow-7.webp', '/images/slideshow-7.webp'],
  description: 'Mixed Media',
  status: 'Available',
  price: '$475',
};

const product8 = {
  title: 'Broken',
  size: '12" by 12" ',
  imageArray: ['/images/slideshow-8.webp', '/images/slideshow-8.webp'],
  description: 'Mixed Media',
  status: 'Available',
  price: '$475',
};

const product9 = {
  title: 'Hidden',
  size: '12" by 12" ',
  imageArray: ['/images/slideshow-9.webp', '/images/slideshow-9.webp'],
  description: 'Mixed Media',
  status: 'Available',
  price: '$475',
};

const product10 = {
  title: 'Chaos',
  size: '12" by 12" ',
  imageArray: ['/images/slideshow-10.webp', '/images/slideshow-10.webp'],
  description: 'Mixed Media',
  status: 'Available',
  price: '$475',
};

const product11 = {
  title: 'Crimson Echos',
  size: '12" by 12" ',
  imageArray: ['/images/slideshow-11.webp', '/images/slideshow-11.webp'],
  description: 'Mixed Media',
  status: 'Available',
  price: '$525',
};

const product12 = {
  title: 'Winged Facade',
  size: '12" by 12" ',
  imageArray: ['/images/slideshow-12.webp', '/images/slideshow-12.webp'],
  description: 'Mixed Media',
  status: 'Available',
  price: '$525',
};

const product13 = {
  title: 'Collapsed',
  size: '12" by 12" ',
  imageArray: ['/images/slideshow-13.webp', '/images/slideshow-13.webp'],
  description: 'Mixed Media',
  status: 'Available',
  price: '$525',
};

const product14 = {
  title: 'Mechanized',
  size: '12" by 12" ',
  imageArray: ['/images/slideshow-14.webp', '/images/slideshow-14.webp'],
  description: 'Mixed Media',
  status: 'Available',
  price: '$525',
};

const product15 = {
  title: 'Dissolved',
  size: '12" by 12" ',
  imageArray: ['/images/slideshow-15.webp', '/images/slideshow-15.webp'],
  description: 'Mixed Media',
  status: 'Available',
  price: '$525',
};

const product16 = {
  title: 'Synthetic Empath',
  size: '12" by 12" ',
  imageArray: ['/images/slideshow-16.webp', '/images/slideshow-16.webp'],
  description: 'Mixed Media',
  status: 'Available',
  price: '$525',
};

const productList = [product1, product2, product3, product4,
  product5, product6, product7, product8, product9, product10,
  product11, product12, product13, product14, product15, product16];

export default productList;
